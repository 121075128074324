import React from 'react';
import {graphql} from 'gatsby';
import loadable from '@loadable/component';

const PageHeader = loadable(() => import('../components/PageHeader'));
const Content = loadable(() => import('../components/Content'));
const Layout = loadable(() => import('../components/Layout'));
const SideBar = loadable(() => import('../components/SideBar'));
import './SoinPage.scss';
import PostSection from '../components/PostSection';

// Export Template for use in CMS preview
export const SoinPageTemplate = ({
                                     title,
                                     subtitle,
                                     slug,
                                     featuredImage,
                                     posts,
                                     body
                                 }) => {

    const filteredPosts = posts && posts.filter(post => post.specialites.includes(title));
    return (
        <main className="SoinPage">
            <PageHeader
                title=''
                subtitle=''
                backgroundImage='https://ucarecdn.com/32047947-37f2-4cb8-89cf-8a0d99792214/'
            />

            <section className="section">
                <div className="container flex flex-col-reverse md:flex-row">
                    <SideBar></SideBar>
                    <div className="SoinPape-Contenu">
                        <h1 className="text-primary font-bold text-6xl mb-8">{title}</h1>
                        <Content source={body}/>
                    </div>
                </div>
            </section>

            {filteredPosts && filteredPosts.length > 0 && (
                <section className="section">
                    <div className="container">
                        <h2 className="text-left text-5xl">Articles liées</h2>
                        <PostSection posts={filteredPosts}/>
                    </div>
                </section>
            )}

        </main>
    )
};


const SoinPage = ({data: {page, posts}}) => (
    <Layout
        meta={page.frontmatter.meta || false}
        title={page.frontmatter.title || false}
    >
        <SoinPageTemplate {...page.frontmatter}
                          body={page.html}
                          posts={posts.edges.map(post => ({
                              ...post.node,
                              ...post.node.frontmatter,
                              ...post.node.fields
                          }))}/>
    </Layout>
);

export default SoinPage

export const pageQuery = graphql`
  query SoinPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        slug
        featuredImage
      }
    }

    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "actualites" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            specialites
            categories {
              category
            }
            featuredImage
          }
        }
      }
    }
  }
`;
